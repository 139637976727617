<script>
	import Layout from "../../layouts/main"
	import PageHeader from "@/components/page-header"
	import appConfig from "@/app.config"
	import Multiselect from "vue-multiselect"
	import { required } from "vuelidate/lib/validators"

	/**
	 * Form-upload component
	 */
	export default {
		components: {
			Layout,
			PageHeader,
			Multiselect,
		},
		page: {
			title: "Add Employee",
			meta: [
				{
					name: "description",
					content: appConfig.description,
				},
			],
		},
		validations: {
			branch: {
				required,
			}
		},
		data() {
			return {
				title: "Add Employee",
				items: [
					{
						text: "Forms",
						href: "/",
					},
					{
						text: "Add Employee",
						active: true,
					},
				],
				corporateId: '',
				loading: false,
				candidateName: "",
				number: "",
				data: [],
				journey: null,
				journeyList: [],
				offerExpiry: "",
				employeeFile: null,
				branch: null,
				branchs: [],
				uploadProgress: 0,
				uploading: false,
				downloadUrl: 'https://ob-staging.s3.ap-south-1.amazonaws.com/Munshi+-+Employee+Details.xlsx',
			};
		},
		mounted() {
			this.corporateId = localStorage.getItem('corporateId')
			this.getCorporateBranch()
		},
		methods: {
			getCorporateBranch: function() {
				this.axios.post('corporate/get-branch', {
					corporateId: (this.corporateId !== undefined && this.corporateId !== null) ? this.corporateId : this.$storageData.corporateId
				}).then((result) => {
					this.branchs = [];
					for(let i = 0; i < result.data.data.length;i++) {
						this.branchs.push({
							branchId: result.data.data[i]._id,
							name: result.data.data[i].name,
						});
					}
					this.branch = this.branchs[0]
				}).catch((error) => {
					console.log(error)
				});
			},
			onPdfUpload(file, response) {
				console.log(file);
				console.log(response);
			},
			handleFileUpload(event) {
				this.employeeFile = event.target.files[0];
				/*this.axios.post('addCorporateEmployee', //TODO change this url
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}
				).then((response) => {
					console.log(response.data.data);
				});*/
			},
			handleClick: function () { },
			addEmployee: function() {
				//this.file = this.employeeFile
				console.log(this.branch)
				this.uploading = true
				let formData = new FormData()
				formData.append('file', this.employeeFile)
				formData.append('branchId', this.branch.branchId)
				formData.append('corporateId', (this.corporateId !== undefined || this.corporateId !== null) ? this.corporateId : this.$storageData.corporateId)
				console.log(this.employeeFile)
				this.axios.post('addCorporateEmployee', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					},
					onUploadProgress: function(progress) {
						this.uploadProgress = parseInt(Math.round((progress.loaded / progress.total) * 100))
					}.bind(this),
				}).then((response) => {
					this.uploading = false
					let statusCode = response.data.responseCode
					if (statusCode === 200) {
						window.location.reload()
					}
					if (statusCode === 400) {
						this.$toast.error(response.data.response, {
							position: "top-right",
							timeout: 5000,
							closeOnClick: true,
							pauseOnFocusLoss: true,
							pauseOnHover: true,
							draggable: true,
							draggablePercent: 0.6,
							showCloseButtonOnHover: false,
							hideProgressBar: false,
							closeButton: "button",
							icon: true,
							rtl: false
						})
					}
				}).catch((error) => {
					console.log(error)
				})
			},
			/*downloadItem: function() {
				this.axios.get(this.downloadUrl, {
					responseType: 'blob',
				}).then(result => {
					console.log(result.data);
				}).catch(console.error)
			},*/
		},
		middleware: "authentication",
	};
</script>
<style>
</style>
<template>
	<Layout>
		<PageHeader :items="items" />
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<h4 class="card-title">Upload Employee Excel</h4>
						<!-- file upload -->
						<div class="p-2 mt-4">
							<div class="dowanload-example mb-2">
								<a :href="downloadUrl">Click here to download sample excel</a>
							</div>
							<b-form @submit.prevent="addEmployee($event)" enctype="multipart/form-data">
								<div class="row">
									<div class="col-lg-6">
										<b-form-group class="mb-3" label="Branch" for="branch">
											<multiselect
												id="branch"
												:options="branchs"
												v-model="branch"
												class="helo"
												placeholder="Select Branch"
												track-by="name"
												label="name"
												:allow-empty="false"
												:class="{ 'is-invalid': $v.branch.$error }"
												:multiple="false">
												<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
											</multiselect>
											<div v-if="$v.branch.$error" class="invalid-feedback">
												<span v-if="$v.branch.required">Please Select branch</span>
											</div>
										</b-form-group>
									</div>
									<div class="col-lg-6">
										<b-form-group class="mb-3" label="Select .xlxs, .xls file" for="resume">
											<b-form-file
												id="resume"
												v-model="employeeFile"
												class="form-control-file"
												placeholder="Select file"
												@change="handleFileUpload($event)" plain />
										</b-form-group>
									</div>
									<div class="mt-3 text-end">
										<progress max="100" animated="true" striped="true" v-if="uploading" :value.prop="uploadProgress"></progress>
										<b-button class="btn-btn-outline-primary mt-4" v-if="!uploading" type="submit" @click="addEmployee" variant="primary">Submit</b-button>
									</div>
								</div>
							</b-form>
						</div>
					</div>
					<!-- end card-body -->
				</div>
				<!-- end card -->
			</div>
			<!-- end col -->
		</div>
		<!-- end row -->
	</Layout>
</template>
